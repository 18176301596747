import {Toast, Image, NavBar, Icon, ActionSheet } from "vant";
import _ from 'loadsh'

export default {
  // name: 'KeepAlive',
  components:{
    'van-image' : Image,
    'van-icon' : Icon,
    'van-nav-bar' : NavBar,
    'van-action-sheet' : ActionSheet,
  },
  data () {
    return {
      apis:{
        findCityCard:'/cityCard/findCityCard',
        recommendList:"/cityCard/queryAll",//城市列表
        tagList:'/tag/list',//标签列表
        getAllArea: '/baseArea/getAllArea'
      },
      content:{},
      cityCardId:'',//城市名片id
      //上啦加载下拉刷新参数
      isLoading: false,
      isShowLoginPop: false,
      list: [],
      name:"",//城市名字
      loading: false,
      finished: false,//没有更多
      textState:true,//详情展开
      historyState:true,//历史展开
      tags:[],//标签列表
      tagsList:[],//标签列表
      weartch:{},//天气
      audio:null,
      params:{
        cityCardId:'',
        pageIndex:1,
        pageSize:10,
        // sortName:"",
        // sortOrder:'asc',
        tagId:""
      },
      playerOptions : {
        playbackRates : [ 0.5, 1.0, 1.5, 2.0 ], //可选择的播放速度
        autoplay : false, //如果true,浏览器准备好时开始回放。
        muted : false, // 默认情况下将会消除任何音频。
        loop : false, // 视频一结束就重新开始。
        preload : 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language : 'zh-CN',
        aspectRatio : '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid : true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources : [ {
          type : "",
          src : null//url地址
        } ],
        poster : require('./img/{BF5804AC-E731-0B5D-8D98-9CB331BAF2B0}.jpg'), //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage : '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar : {
          timeDivider : true,//当前时间和持续时间的分隔符
          durationDisplay : true,//显示持续时间
          remainingTimeDisplay : false,//是否显示剩余时间功能
          fullscreenToggle : true  //全屏按钮
        }
      },
      videos:[],
      showPop:false,
      acTab:0,
      actions: [],
    }

  },
  mounted () {
    const me = this;
    if(sessionStorage.getItem('cityObj')){
      me.cityCardId = JSON.parse(sessionStorage.getItem('cityObj')).areaId;
      me.name = JSON.parse(sessionStorage.getItem('cityObj')).name;
    }else{
      me.cityCardId = JSON.parse(me.$route.query.obj).areaId;
      me.name = JSON.parse(me.$route.query.obj).name;
    }

    me.initCity();
    me.init();
    me.wather()


  },
  created(){

    this.getShareInfo(
      this.$global.shareTitle,
      this.$global.visitUrl,
      this.$global.shareImage,
      this.$global.shareIntro,
    );
  },
  methods:{
    onSelect(item) {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      this.show = false;
      this.cityCardId = item.areaId;
      this.name = item.name;
      sessionStorage.setItem('cityObj',JSON.stringify(item));
      this.initCity(1);
    },

    init () {
      const me = this;
      me.$get({
        url:me.apis.getAllArea,
        params:{
          adCode:"qy-ls"
        }
      }).then(rep=>{
        me.actions=rep

      })
    },
    exit(){
      this.$router.go(-1)
    },
    routerPush(obj){
      switch (obj.bizType) {//根据biztype跳转页面
        case 10 :
          this.$router.push(`/foodrank/?uniqueId=${obj.bizId}`)
          break;
        case 5 :
          this.$router.push(`/guideDetails/?bizId=${obj.bizId}`)
          break;
        case 3 :
          if(!this.$global.token){
            this.isShowLoginPop = true;
          }else{
            this.$router.push(`/tiketDetails/${obj.bizId}?back=self`+'&priceCalendarCount='+obj.priceCalendarCount)
          }
          break;
        case 4 :
          this.$router.push(`/mallDetails/?commodityId=${obj.bizId}`)
          break;
        case 6:
          if(obj.videoType==3){
            this.$router.push('/liveDetail/' + obj.bizId);
          }else if(obj.videoType==2){
            window.location.href = obj.interlinkage;
            /*this.$router.push({
              path:'/outWindowBlank',
              query:{
                src:encodeURI(obj.interlinkage)
              }
            })*/
            // window.open(obj.interlinkage)
            // this.$router.push('/liveArVr/' + obj.bizId);
          }else if(obj.videoType==1) {
            this.$router.push('/live5g/' + obj.bizId);
          }
          break;
      }

    },
    wather(){
      const me =  this;
      me.$axios.get(`http://111.9.253.19:8090/weatherkit/weatherjson/${JSON.parse(this.$route.query.obj).cityCode}`,{}).then(rep=>{
        if(rep.data.weatherJson){
          me.weartch = rep.data.weatherJson.today
          me.weartch.state = true
        }else{
          me.weartch.state = false

        }


      })



    },
    initCity (num) {//城市详情接口
      const me = this;
      me.$get({
        url:me.apis.findCityCard,
        params:{
          areaId: me.cityCardId
        }
      }).then(rep=>{
        me.content=rep;
        if(num){
          me.videos = [];
        }
        if(me.content.attachments&&me.content.attachments.length){
          let td = me.content.attachments
          for(let i in td){
           if(td[i].fileType==2){
             me.playerOptions.sources[0].src = td[i].url;
             me.playerOptions.poster = td[i].coverAttachment.url;

             let arr = _.cloneDeep(me.playerOptions)
             me.videos.push(arr)
           }
          }
        }

        me.params.cityCardId= rep.cityCardId;
        me.tagFunc();
      })
    },
    tagFunc(){//标签列表
      const me = this;
      me.$get({
        url:me.apis.tagList,
        params:{
          type: 1
        }
      }).then(rep=>{
        me.tags = rep.data;
        me.tagsList = JSON.parse(JSON.stringify(rep.data));
        if(me.tags || me.tags.length){
          me.allList(0);
        }

      })
    },
    audioFunc(){//音频播放
      const me=this;
      if(this.content.audiobook){
        this.$toast('没有音乐文件啦')
        return
      }
      me.audio = new Audio();
      me.audio.src = me.content.audiobook;

      if(this.audio.paused){
        this.audio.play()
      }else{
        this.audio.pause();
      }
    },
    allList (key) {//
      const me = this;
      me.acTab = key;
      me.$get({
        url:me.apis.recommendList,
        params:{
          cityCardId:me.params.cityCardId,
          pageIndex:1,
          pageSize:10,
          // sortName:"",
          // sortOrder:'asc',
          tagId:me.tagsList[key].tagId
        }
      }).then(rep=>{
        me.list = JSON.parse(JSON.stringify(rep.data));
      })
    },
  },
  destroyed(){//销毁音频
    if(this.content.audiobook){
      this.audio.pause();
    }

  }

}
